import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as RouterActions from '../actions/router.action';

@Injectable()
export class RouterEffects {
  readonly #actions$ = inject(Actions);
  readonly #router = inject(Router);

  readonly navigate$ = createEffect(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => {
      return this.#actions$.pipe(
        ofType(RouterActions.go),
        map((action) => action.payload),
        // eslint-disable-next-line unicorn/prevent-abbreviations -- TODO: delete the comment and fix the error
        tap(({ path, query: queryParams, extras }) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: delete the comment and fix the error
          this.#router.navigate(path, { queryParams, ...extras });
        }),
      );
    },
    { dispatch: false },
  );
}
