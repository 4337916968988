import { inject, Injectable, type Type } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { filter, map, tap } from 'rxjs';
import { AuthFacade } from '../../auth/store/auth.facade';
import { DemoSlidesModalService } from '../components/demo-slides-modal/demo-slides-modal.service';
import { SupplierSegmentsDemoComponent } from '../components/supplier-segments-demo-slides/supplier-segments-demo.component';
import { GuideId } from '../models/guides.models';
import * as GuidesActions from './guides.actions';
import { GuidesFacade } from './guides.facade';

const GUIDE_ID_TO_COMPONENT_MAP = {
  [GuideId.SUPPLIER_SEGMENTS_DEMO]: SupplierSegmentsDemoComponent,
} as const satisfies Record<GuideId, Type<unknown>>;

/**
 * TODO: Add logic for saving and retrieving completed guides for the user.
 * TODO: Implement automatic guide display (possibility to register guides that should open automatically).
 * TODO: Implement a queue for pending guides. A new guide should not open while the previous one is still visible.
 */
@Injectable()
export class GuidesEffects {
  readonly #actions$ = inject(Actions);
  readonly #authFacade = inject(AuthFacade);
  readonly #demoSlidesModalService = inject(DemoSlidesModalService);
  readonly #guidesFacade = inject(GuidesFacade);

  readonly showGuide$ = createEffect(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => {
      return this.#actions$.pipe(
        ofType(GuidesActions.openGuide),
        tap((action) => {
          this.#displayGuide(action.guideId);
        }),
      );
    },
    { dispatch: false },
  );

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly notifyGuideCompleted$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(GuidesActions.guideClosed),
      filter(({ isGuideCompleted }) => isGuideCompleted),
      concatLatestFrom(() => this.#authFacade.userId$),
      map(([{ guideId }, userId]) =>
        GuidesActions.guideCompleted({ guideId, userId }),
      ),
    );
  });

  #displayGuide(guideId: GuideId): void {
    this.#demoSlidesModalService
      .open(GUIDE_ID_TO_COMPONENT_MAP[guideId])
      .afterClosed()
      .pipe(
        tap((result) => {
          this.#guidesFacade.notifyGuideClosed(
            guideId,
            result?.isGuideCompleted ?? false,
          );
        }),
      )
      .subscribe();
  }
}
