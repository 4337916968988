import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';
import { LanguagesService } from '../../services/languages.service';
import * as LanguagesActions from '../actions/languages.actions';

@Injectable()
export class LanguagesEffects {
  readonly #actions$ = inject(Actions);
  readonly #authService = inject(AuthService);
  readonly #document = inject(DOCUMENT);
  readonly #languagesService = inject(LanguagesService);

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly updateActiveLanguage$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(LanguagesActions.updateActiveLanguage),
      switchMap(({ languageCode }) => {
        if (!this.#authService.isAuthenticated) {
          return of(
            LanguagesActions.updateActiveLanguageSuccess({
              languageCode,
            }),
          );
        }

        return this.#languagesService
          .updateCurrentUserLanguage(languageCode)
          .pipe(
            map(() =>
              LanguagesActions.updateActiveLanguageSuccess({
                languageCode,
              }),
            ),
            catchError((error: unknown) =>
              of(
                LanguagesActions.updateActiveLanguageFail({
                  error,
                  languageCode,
                }),
              ),
            ),
          );
      }),
      tap(({ languageCode }) => {
        this.#languagesService.storeActiveLanguageInLocalStorage(languageCode);
        this.#document.location.reload();
      }),
    );
  });
}
