import { inject, Injectable } from '@angular/core';
import { marker } from '@jsverse/transloco-keys-manager/marker';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, map, of, switchMap } from 'rxjs';
import { ERROR_DETAILS_KEY } from '../../../core/consts/error.consts';
import { getErrorDetails } from '../../../core/helpers/get-error-details.helper';
import { getErrorMessage } from '../../../core/helpers/get-error-message.helper';
import {
  LoggerService,
  MessageLevel,
} from '../../../core/services/logger.service';
import { isArray } from '../../../shared/helpers/is-array/is-array.helper';
import * as MessageActions from '../../../shared/store/actions';
import { SharedFacade } from '../../../shared/store/shared.facade';
import {
  signupFormDTOsToSignupForms,
  signUpFormTitleDataDTOToSignupFormTitleData,
} from '../../models/signup.models';
import { SignupService } from '../../services/signup.service';
import * as SignupActions from '../actions/signup.actions';

@Injectable()
export class SignupEffects {
  readonly #actions$ = inject(Actions);
  readonly #loggerService = inject(LoggerService);
  readonly #sharedFacade = inject(SharedFacade);
  readonly #signupService = inject(SignupService);

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly getForms$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(SignupActions.getForms),
      concatLatestFrom(() => [this.#sharedFacade.accountId$]),
      switchMap(([, accountId]) =>
        this.#signupService.getSignupForms(accountId).pipe(
          map((formDTOs) =>
            SignupActions.getFormsSuccess({
              forms: signupFormDTOsToSignupForms(formDTOs),
            }),
          ),
          catchError((error: unknown) => {
            this.#logError(error);

            return of(SignupActions.getFormsFail({ error }));
          }),
        ),
      ),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly getBackgroundImage$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(SignupActions.getBackgroundImage),
      switchMap(({ formId }) =>
        this.#signupService.getFormBackgroundImage(formId).pipe(
          map((backgroundImageBase64) =>
            SignupActions.getBackgroundImageSuccess({
              backgroundImageBase64:
                isArray(backgroundImageBase64) ? '' : backgroundImageBase64,
            }),
          ),
          catchError((error: unknown) => {
            this.#logError(error);

            return of(SignupActions.getBackgroundImageFail({ error }));
          }),
        ),
      ),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly getLogo$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(SignupActions.getLogo),
      switchMap(({ formId }) =>
        this.#signupService.getFormLogo(formId).pipe(
          map((logoBase64) =>
            SignupActions.getLogoSuccess({
              logoBase64: isArray(logoBase64) ? '' : logoBase64,
            }),
          ),
          catchError((error: unknown) => {
            this.#logError(error);

            return of(SignupActions.getLogoFail({ error }));
          }),
        ),
      ),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly getLogoTitleData$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(SignupActions.getLogoTitleData),
      switchMap(({ formId }) =>
        this.#signupService.getFormLogoTitleData(formId).pipe(
          map((titleDataDTO) =>
            SignupActions.getLogoTitleDataSuccess({
              titleData:
                signUpFormTitleDataDTOToSignupFormTitleData(titleDataDTO),
            }),
          ),
          catchError((error: unknown) => {
            this.#logError(error);

            return of(SignupActions.getLogoTitleDataFail({ error }));
          }),
        ),
      ),
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly enqueueErrorSnackbar$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(
        SignupActions.getFormsFail,
        SignupActions.getLogoFail,
        SignupActions.getLogoTitleDataFail,
      ),
      map((action) => {
        const getErrorSnackbarConfiguration =
          (): MessageActions.EnqueueSnackbarConfiguration => {
            switch (action.type) {
              case SignupActions.getFormsFail.type: {
                return {
                  translationKey: marker(
                    'authModule.snackbarMessage.getFormsFail',
                  ),
                };
              }
              case SignupActions.getLogoFail.type: {
                return {
                  translationKey: marker(
                    'authModule.snackbarMessage.getLogoFail',
                  ),
                };
              }
              case SignupActions.getLogoTitleDataFail.type: {
                return {
                  translationKey: marker(
                    'authModule.snackbarMessage.getLogoTitleDataFail',
                  ),
                };
              }
            }
          };

        return MessageActions.enqueueErrorSnackbar({
          configuration: getErrorSnackbarConfiguration(),
        });
      }),
    );
  });

  #logError(error: unknown): void {
    this.#loggerService.log({
      level: MessageLevel.Error,
      message: getErrorMessage(error),
      data: {
        [ERROR_DETAILS_KEY]: getErrorDetails(error),
      },
    });
  }
}
